import { VENDORS_FEES, VENDORS_FEES_SHOW } from "../endpoints";
import api from "../api";

export default {
    index(vendor = null, query = null) {
        if (!vendor)
            return;

        const url = VENDORS_FEES.replace(":vendor", vendor);

        if (query !== null) {
            url.search = new URLSearchParams(query).toString();;
        }

        return api.get(url);
    },

    store(data, { vendor }) {
        if (data === null) return;

        const url = VENDORS_FEES.replace(":vendor", vendor);

        return api.post(url, data);
    },

    destroy(vendor = null, fee = null) {
        if ((vendor === null) || (fee === null)) return

        const url = VENDORS_FEES_SHOW.replace(":vendor", vendor).replace(':fee', fee);

        return api.delete(url);
    }
}